<template>
  <div>
    <div class="phone-input" :class="{ error: phoneError, borderless }">
      <q-select
        :class="{ borderless }"
        :value="dialcode"
        :display-value="dialcode ? `+${dialcode}` : ''"
        :options="callingCodeSelect"
        filter
        emit-value
        stack-label
        map-options
        hide-dropdown-icon
        use-input
        :borderless="borderless"
        :disable="disabled"
        :label="$t('phone_geo_code')"
        @filter="dialCodeFilter"
        @input="val => handleDialcode(val)"
      >
        <template v-slot:loading />
      </q-select>
      <q-input
        ref="phone"
        :class="{ borderless }"
        :value="phone"
        stack-label
        color="primary"
        :borderless="borderless"
        :disable="disabled"
        :label="$t('phone_number')"
        @input="val => $emit('phone', val)"
      />
    </div>
    <div v-if="phoneError" class="phone-error">
      <div role="alert" class="text-body2 text-red">
        {{ $t('enter_valid_phone_number') }}
      </div>
    </div>
  </div>
</template>

<script>
import { dialCodes } from 'utils/quasar-countries'

const callingCodeSelect = dialCodes

export default {
  name: 'MSelectDialCode',
  props: {
    dialcode: String,
    phone: String,
    phoneError: Boolean,
    borderless: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      callingCodeSelect
    }
  },
  methods: {
    dialCodeFilter (val, update) {
      if (val) {
        update(() => {
          this.callingCodeSelect = callingCodeSelect.filter(({ value, country }) => {
            let search = val.replace('+', '')
            return value.startsWith(search) || country.startsWith(search.toLowerCase())
          })
        })
      }
    },
    handleDialcode (val) {
      this.$emit('dialcode', val)
      if (val) {
        this.$nextTick(() => {
          this.$refs.phone.focus()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.borderless
  margin-bottom -8px
  margin-top -8px

.q-select
  >>> .q-field__input
    min-width 0 !important

.phone-input
  display grid
  grid-template-columns 60px auto
  grid-template-rows 1fr auto
  @media (max-width 599px)
    padding 8px 12px
    border 1px solid #ccc
    border-radius 20px
    &.borderless
      border none

.phone-error
  grid-column 1 / span 3

@keyframes shake {
  0% {
    transform translateX(-2px)
  }
  33% {
    transform translateX(2px)
  }
  66% {
    transform translateX(-2px)
  }
  100% {
    transform translateX(2px)
  }
}

@keyframes slide-down {
  0% {
    opacity 0%
    transform: translateY(-1.2em)
    }
  100% {
    oppacity 100%
    transform: translateY(0)
    }
}

div[role=alert]
  height 1.2em
  color red
  margin 0 16px -1.2em
  transform: translateY(0px)
  animation slide-down 0.8s
  font-size 80%
</style>
